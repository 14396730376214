import React from "react"
import styled from "styled-components"
import Form from "./form"
import Team from "./team"

const ContactInfo = ({data, small}) => {
  
  return(
    <StyledContact style={{flexDirection: small && "column", backgroundColor: small && "unset", paddingTop: small && "20px"}}>
      <Form data={data.form}/>
      <span style={{width: "5vw", height: small && "0"}}/>
      <Team data={data.team}/>
    </StyledContact>
  )
}

export default ContactInfo

const StyledContact = styled.div`
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3vw;
`