import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Menu from "../components/menu/menu"
import Address from "../components/contatos/address"
import Intro from "../components/contatos/intro"
import IntroMobile from "../components/contatos/introMobile"
import ContactInfo from "../components/contatos/contactInfo"
import Mapa from "../components/contatos/mapa"
import Footer from "../components/footer/footer"

const ContatosPage = ({data}) => {
  
  return(
    <Layout inv={data.globalJson.invisual}>
      <SEO title="Contatos"/>
      {useBreakpoint().mobile ? (
      <>
        <Menu data={data.globalJson.menu}/>
        <IntroMobile data={data.contatosJson.intro}/>
        <Address data={data.contatosJson.address} small/>
        <ContactInfo data={data.contatosJson.contactInfo} small/>
        <Mapa />
        <Footer data={data.globalJson.footer} small/>
      </>
    ) : (
      <>
        <Menu data={data.globalJson.menu}/>
        {/* <div style={{height: "8vw"}}/> */}
        <Intro data={data.contatosJson.intro} address={data.contatosJson.address}/>
        {/* <Address data={data.contatosJson.address}/> */}
        <ContactInfo data={data.contatosJson.contactInfo}/>
        <Mapa />
        <Footer data={data.globalJson.footer}/>
      </>
    )}
    </Layout>
  )
}
export default ContatosPage

export const Json = graphql`
  query contatos {
    contatosJson{
      address
      intro{
        title
        mapa{
          childImageSharp{
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      contactInfo{
        team{
          title
          name
          phone
          txt
          email
        }
        form{
          title
          type{
            title
            option
          }
          name
          email
          phone
          message
          termos{
            link
            linkTxt
          }
          send
        }
      }
    }
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
      footer{
        livro{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
        bg{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacts{
          email
          phone1
        }
        address
        privacyInfo{
          linkTxt
          link
        }
      }
    }
  }
`