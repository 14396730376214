import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Team = ({ data, small }) => {

  return (
    <StyledTeam style={{ flexDirection: small && "column", backgroundColor: small && "unset", paddingTop: small && "50px" }}>
      <div className="address-column">
        {data[0].map((data, i) => (
          <p className={i !== 0 ? font.aGR : font.mER + " upper"} key={"address" + i}>{data}</p>
        ))}
      </div>
      <div className="address-column">
        <p className={font.aGR}><br /></p>
        {data[1].map((data, i) => (
          <a href={i === 0 ? "mailto:" + data : "tel:" + data} className={font.aGR} key={"contacts" + i}>{data}</a>
        ))}
        <a className="banner">«Chamada para a rede fixa nacional»</a>
      </div>
    </StyledTeam>
  )
}

export default Team

const StyledTeam = styled.div`
  position: relative;
  background-color: #f7f7f7;
  color: #25282a;
  padding: 9vw 30vw 2vw 10vw;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  .upper{text-transform: uppercase;}
  @media only screen and (max-width: 768px){
    .address-column{margin-bottom: 20px;margin-left: 7%;}
  }
`