import React from "react"
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import logo from "../../images/global/logo-branco.svg"

const Footer = ({ data, small }) => {
  const image = data.bg.childImageSharp.fluid;

  return (
    <StyledFooter fluid={image}>
      <Link to="/" className="footer-logo">
        <img src={logo} alt="Logo" />
      </Link>
      <p className={small ? font.aGR + " small footer-address" : font.aGR + " footer-address"}>
        {data.address.map((data, i) => (
          <span key={"address" + i}>{data}<br /></span>
        ))}
      </p>
      <div className="footer-contacts">
        <a href={"mailto: " + data.contacts.email} className={small ? font.aGR + " small" : font.aGR}>{data.contacts.email}</a>
        <a href={"tel: " + data.contacts.phone1} className={small ? font.aGR + " small" : font.aGR}>{data.contacts.phone1}</a>
        <small className={font.aGR}>«Chamada para a rede fixa nacional»</small>
      </div>
      <div className="footer-contacts">
        {data.privacyInfo.map((data, i) => (
          i !== 3 ?
            <a href={data.link} target="_blank" rel="noopener noreferrer" className={small ? font.aGR + " small" : font.aGR} key={"privacy" + i}>{data.linkTxt}</a>
            :
            <a href={data.link} target="_blank" rel="noopener noreferrer" className={font.aGR + " small created"} key={"privacy" + i}>{data.linkTxt}</a>
        ))}
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled(BackgroundImage)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5vw 12vw 5vw 7vw;
  border-top: 1px solid #fa4616;
  color: #ffffff;
  .footer-contacts{
    a{
      line-height: 1.5em;
    }
  }
  .footer-logo{
    margin: 0 1vw;
    width: clamp(150px, 12vw, 350px);
    img{width: 100%;}
  }

  .created{
    position: absolute;
    bottom: 1em;
    right: 1em;
  }
  @media only screen and (max-width: 1500px){
    .footer-contacts{
      margin: 0 2vw;
    }
  }
  @media only screen and (max-width: 768px){
    display: block;
    .footer-logo{
      margin: 3vw auto 10vw;
      width: 45%;
    }
    .footer-contacts{
      margin: 2vw 0 6vw;
    }
    .footer-privacy{
      text-decoration: underline;
      margin: 2px;
    }
  }
`