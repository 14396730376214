import React from "react"
import styled from "styled-components"

const Mapa = () => {
  
  return(
    <StyledMapa>
      <iframe src="https://snazzymaps.com/embed/310233" className="map"></iframe>
    </StyledMapa>
  )
}

export default Mapa

const StyledMapa = styled.div`
  position: relative;
  height: 33vw;
  width: 100%;
  .map{
    width: 100%;
    height: 100%;
    border: 0;
  }
  @media only screen and (max-width: 768px){
    height: 100vw;
  }
`