import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import font from "../../fonts/fonts.module.scss"

import logo from "../../images/global/logo-laranja.svg"

const Team = ({data}) => {
  
  return(
    <StyledTeam>
      {data.map((data, i)=>(
        <div className="team" key={"member" + i}>
          {/* <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={"Foto"} className="team-photo"/> */}
          {/* <p className={font.aGM + " title"}>{data.title}</p> */}
          <h5 className={font.mER + " name"}><img src={logo} alt="Logo"/>{data.name}</h5>
          {/* <a href={"tel:" + data.phone} className={font.aGR}>{data.phone}</a> */}
          <a href={"mailto:" + data.email} className={font.aGR}>{data.email}</a>
        </div>
      ))}
    </StyledTeam>
  )
}

export default Team

const StyledTeam = styled.div`
  position: relative;
  color: #25282a;
  display: flex;
  .team{
    position: relative;
    margin: 5vw 3vw;
    .team-photo{width: clamp(200px, 14vw, 400px);}
    .title{color: #fa4616;}
    .name{
      margin: 0.6em 0;
      display: flex;
      align-items: center;
      img{
        position: absolute;
        left: -2em;
        height: 1em;
        margin-right: 0.4em;
      }
    }
    .title, .name{text-transform: uppercase;}
  }
  @media only screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 10vw;
    .team{
      margin: 10vw 0;
      .team-photo{width: 100%;}
    }
  }
`