import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'

const Intro = ({data}) => {
  
  return(
    <StyledIntro>
      <StyledBackground fluid={data.mapa.childImageSharp.fluid}>
        <div className="intro-banner-title">
          {/* <h2 className={font.mER + " title"}>{data.title}</h2>
          <p className={font.aGR + " text"}>{data.text}</p> */}
        </div>
      </StyledBackground>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  width: 100%;
  height: 80vw;
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .intro-banner-title{
    width: 60%;
    margin-left: 20%;
    .title{margin-bottom: 0.6em;}
  }
  @media only screen and (max-width: 1100px){
    .intro-banner-title{
      width: 80%;
      margin-left: 10%;
    }
  }
  @media only screen and (max-width: 950px){
    .intro-banner-title{
      width: 90%;
      margin-left: 5%;
    }
  }
  @media only screen and (max-width: 830px){
    .intro-banner-title{
      width: 96%;
      margin-left: 2%;
    }
  }
  @media only screen and (max-width: 768px){
    width: 100%;
    .intro-banner-title{
      margin-top: -7vw;
      width: 86%;
      margin-left: 7%;
      .title{margin-bottom: 0.2em;}
    }
  }
`