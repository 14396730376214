import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import font from "../../fonts/fonts.module.scss"

const Intro = ({ data, address }) => {

  return (
    <StyledIntro>
      <StyledBackground fluid={data.mapa.childImageSharp.fluid}>
        <div className="intro-banner-title">
          {/* <h2 className={font.mER + " title"}>{data.title}</h2>
          <p className={font.aGR + " text"}>{data.text}</p> */}
        </div>
      </StyledBackground>
      <div className="banner-name">
        {/* <h1 className={font.aGB}>{data.title[0]}<br/>{data.title[1]}</h1> */}
        <div className="address-column">
          {address[0].map((data, i) => (
            <p className={i !== 0 ? font.aGR : font.mER + " upper"} key={"address" + i}>{data}</p>
          ))}
          <p className={font.aGR}><br /></p>
          {address[1].map((data, i) => (
            <div style={{marginBottom: "10px"}}>
              <a href={i === 0 ? "mailto:" + data : "tel:" + data} className={font.aGR} key={"contacts" + i}>{data}</a>
            </div>
          ))}
          <a className="teste">«Chamada para a rede fixa nacional»</a>
        </div>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  height: 33vw;
  width: 100%;
  display: flex;
  .map{
    height: 100%;
    width: 60%;
    border: none;
    align-self: flex-end;
  }
  .pointer{
    position: absolute;
    top: 42%;
    left: 29.8%;
    width: 2vw;
  }
  .banner-name{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    color: #fa4616;
  }
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .intro-banner-title{
    width: 60%;
    margin-left: 20%;
    .title{margin-bottom: 0.6em;}
  }
  @media only screen and (max-width: 1100px){
    .intro-banner-title{
      width: 80%;
      margin-left: 10%;
    }
  }
  @media only screen and (max-width: 950px){
    .intro-banner-title{
      width: 90%;
      margin-left: 5%;
    }
  }
  @media only screen and (max-width: 830px){
    .intro-banner-title{
      width: 96%;
      margin-left: 2%;
    }
  }
  @media only screen and (max-width: 768px){
    width: 100%;
    .intro-banner-title{
      margin-top: -7vw;
      width: 86%;
      margin-left: 7%;
      .title{margin-bottom: 0.2em;}
    }
  }
`